import React, {createContext, useState} from "react";

export const MainContext = createContext();

export function MainProvider(props){

    const defaultLocationObj={
        Location: '',
        SummerDescription:'',
        SummerAccount:'',
        WinterDescription:'',
        WinterAccount:'',
        SingleUse:false,
        redeemedCoupons:[]
    }

    const [locationObj, setLocationObj] = useState(defaultLocationObj);
    const [showReport, setShowReport] = useState(false);
    const [showUse, setShowUse] = useState(false);
    const [auth0Token, setAuth0Token] = useState('');

    const changeLocation = (locationObj) =>setLocationObj(locationObj)
    const changeShowReport = ()=>setShowReport(!showReport)
    const changeShowUse = ()=>setShowUse(!showUse)
    const changeAuth0Token = (auth0Token) =>setAuth0Token(auth0Token)


    return (
        <MainContext.Provider value={{ changeLocation, changeShowReport, changeShowUse,changeAuth0Token, locationObj, showReport, showUse, auth0Token}}>
            {props.children}
        </MainContext.Provider>
    );

}