import React, {useState, useContext, useRef, useEffect} from 'react';
import './App.css';
import Login from './Login';
import {MainContext} from './MainContext';
import axios from "axios";
import {Grid, Typography, Button, TextField, CircularProgress} from '@mui/material';
import fred from "./assets/FredSmall.png";
import success from "./assets/gosign.png"
import Alert from "@mui/material/Alert";
import Navbar from './Navbar'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DailyReport from './LocationUseDialog'
import UseReport from './EmpUseDialog'


function App() {

    const {locationObj, showUse, showReport, changeLocation, changeAuth0Token, auth0Token} = useContext(MainContext);
    const [barcode, setBarcode] = useState('');
    const [name, setName] = useState('Fred');
    const [pic, setPic] = useState(fred);
    const [employeeID, setEmployeeID] = useState('');
    const [empUseData, setEmpUseData] = useState([])
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isEligible, setIsEligible] = useState(true)
    const barcodeField = useRef();

    let thePic = '';

    useEffect(() => {
        if (locationObj.Location !== '')
            barcodeField.current.focus();

    }, [locationObj, showReport])


    const getEmployee = async () => {

        setEmpUseData([])
        setIsValid(true)
        if (barcode.length === 0) {
            setPic(fred);
            setName('');
            return
        }
        setLoading(true)

        try {
            await axios.get(process.env.REACT_APP_GET_EMPLOYEE_URL + barcode, {headers: {Authorization: 'Bearer ' + auth0Token}})
                .then(res => {
                    if (res.data.title === 'Not Found') {
                        setPic(fred);
                        setName('');
                        setEmployeeID('')
                        setError(true);
                        setIsEligible(true)
                        // audioFail.play()
                    } else {
                        thePic = "data:image/png;base64," + res.data.picture;
                        setPic(thePic);
                        setName(res.data.name);
                        setEmployeeID((prev)=>res.data.employeePK)
                        setIsEligible(res.data.isSuspend === true || res.data.isActive === false ? false : true)

                        if (res.data.hasOwnProperty('coupons')) {
                            const filteredUse = filterEmpUse(res.data.coupons)
                            setEmpUseData(filteredUse)
                            setIsValid(verifyIsValid(res.data.employeePK, filteredUse))
                        }
                        setError(false);


                        if(barcode.length >6 && res.data.employeePK!=='') //added to auto submit if it is an RFID
                            postData(res.data.employeePK)

                    }
                })
                .catch((e) => {
                    setPic(fred);
                    setName('');
                    setEmployeeID('')
                    setError(true);
                    setBarcode('')
                });
        } catch (err) {
            setError(true);
        }
        setLoading(false)
        // barcodeField.current.focus();
        // toast.success('Success')

    }


    const filterEmpUse = (empUse) => {
        if (locationObj.Location === 'DVLT')
            empUse.filter(row => row.description === 'DVLT Event')
        else
            empUse.filter(row => row.description.substring(1, 2) === '-')

        return empUse
    }

    const verifyIsValid = (id, useArray) => {
        let valid = true
        if (locationObj.singleUse === true && useArray.length > 0) {
            const accountNumber = figureAccount()
            const result = useArray.some(({couponNumber}) => couponNumber === accountNumber)
            if (result === true)
                valid = false
        }
        return valid
    }

    const figureAccount = () => {
        const currentMonth = new Date().getMonth() + 1
        return currentMonth > 4 && currentMonth < 11 ? locationObj.summerAccount : locationObj.winterAccount   //summer is May thru Oct
    }

    const reset = () => {
        setBarcode('');
        setName('');
        setPic(success);
        setEmployeeID('');
        setLoading(false);
        setEmpUseData([{}])
        setError(false);
        setIsEligible(true)
        barcodeField.current.focus();
    };

    const resetError = () => {
        setError(false);
    };


    const logout = () => {

        setBarcode('');
        setName('Fred');
        setPic(fred);
        setEmployeeID('');
        setLoading(false);
        setError(false);
        setIsValid(true);
        setIsEligible(true)

        const defaultLocationObj = {
            Location: '',
            TerminalNumber: 0,
            SummerDescription: '',
            SummerAccount: '',
            WinterDescription: '',
            WinterAccount: ''
        }
        changeLocation(defaultLocationObj)
        changeAuth0Token('')
    }


    const postData = async (empPK) => {

        if (empPK === '') {
            toast.error('Enter Employee ID before trying to REDEEM')
            return
        }

        if (!isValid) {
            toast.error('Already redeemed at this location')
            return
        }

        if (!isEligible && !error) {
            toast.error('Not eligible for this perk')
            return
        }

        const currentMonth = new Date().getMonth() + 1
        const accountNumber = currentMonth > 4 && currentMonth < 11 ? locationObj.summerAccount : locationObj.winterAccount   //summer is May thru Oct
        const location = currentMonth > 4 && currentMonth < 11 ? locationObj.summerDescription : locationObj.winterDescription
        let empObject = {
            CO_EM_PK: empPK,  //get the CO_EM_PK when finding the Employee
            CO_NUMBER: accountNumber,
            CO_DESCRIPTION: location,
            CO_TERMINAL: locationObj.terminalNumber
        }

        setLoading(true)
        try {

            await axios.post(process.env.REACT_APP_POST_URL, empObject, {headers: {Authorization: 'Bearer ' + auth0Token}})
                .then(res => {
                    reset();
                })
                .catch((e) => {
                    setError(true);
                });
        } catch (err) {
            setError(true);
        }
    }

    return (

        <div className="App">
            <Navbar logout={logout}/>

            {locationObj.Location !== '' && <div>

                <Grid item container direction="column" alignItems="center" justify="center">
                    <Grid item style={{maxWidth: "30em"}}>
                        <Typography variant="h4" gutterBottom style={{color: '#038231', marginTop: "20px"}}> Employee
                            Perks Redemption</Typography>
                    </Grid>
                    <Grid item style={{maxWidth: "30em"}}>
                        <Typography variant="h5" gutterBottom
                                    style={{color: '#038231', marginBottom: "10px"}}>{locationObj.location}</Typography>
                    </Grid>
                    <Grid item style={{maxWidth: "30em"}}>
                        <TextField label="Employee ID" id="barcode" inputRef={barcodeField} autoFocus autoComplete="off"
                                   variant="outlined"
                                   value={barcode}
                                   onChange={(e) => setBarcode(e.target.value)}
                                   onBlur={barcode.length !== 0 ? getEmployee : null}/>
                    </Grid>
                    <Grid item alignItems="center" justify="center">
                        <div style={{marginTop: "10px", marginBottom: "10px"}}>
                            <div style={{border: "2px solid darkgreen"}}>
                                {pic && <img src={pic} alt="emp pic"/>}
                            </div>
                        </div>

                    </Grid>

                    {loading ? <Grid item alignItems="center" justify="center"><CircularProgress/></Grid> : null}

                    <Grid item alignItems="center" justify="center">
                        {error ? <Alert severity="error">Employee not found.</Alert> : name}
                    </Grid>
                    <Grid item alignItems="center" justify="center">
                        {!isValid && <Alert severity="error">Already redeemed at this location.</Alert>}
                        {!isEligible && !error && <Alert severity="error">Not eligible for this perk.</Alert>}
                    </Grid>
                </Grid>
                <Grid item alignItems="center" justify="center" style={{marginTop: "10px"}}>
                    {isValid && isEligible && !error &&
                        <Button style={{width: '200px', height: '60px', marginBottom: "40px"}} variant="contained"
                                onClick={()=>postData(employeeID)}>{locationObj.location!=='Leadership Team Event'?'Submit':'Sign In'}</Button>}
                </Grid>
                <p/>
            </div>}
            {locationObj.Location === '' && <Login/>}
            <ToastContainer/>
            {showReport && <DailyReport/>}
            {showUse && <UseReport empID={employeeID} empUse={empUseData}/>}
        </div>
    );
}

export default App;
