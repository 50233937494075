import React, {useContext, useState, forwardRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, Typography, Button, TextField, CircularProgress} from '@mui/material';
import Slide from '@mui/material/Slide';
import {MainContext} from './MainContext';
import axios from "axios";
import logo from './assets/Fred.png'
import Alert from '@mui/material/Alert';
import {toast} from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const AlertDialogSlide = (() => {


    const {changeLocation, changeAuth0Token, auth0Token} = useContext(MainContext);
    const [locationId, setLocationId] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const getToken = async (e) => {
        e.preventDefault()
        setLoading(true);
        if (auth0Token.length === 0) {
            try {
                await axios.post(
                    "https://apidv.us.auth0.com/oauth/token",
                    {
                        "client_id": process.env.REACT_APP_AUTH0APPKEY,
                        "client_secret": process.env.REACT_APP_AUTH0APPSECRET,
                        "audience": "https://api-uat.deervalley.com/hrapi",
                        "grant_type": "client_credentials"
                    },
                    {headers: {"content-type": "application/json"}}
                ).then((response) => {
                    changeAuth0Token(response.data.access_token)
                    validateLocation(response.data.access_token)
                }).catch((err) => {
                    toast.error('Authentication Error')
                    setLoading(false);
                });
            } catch (err) {
                toast.error('Authentication Error')
                setLoading(false);
            }
        } else {
            validateLocation(auth0Token)
        }
    }


    const validateLocation = async (authToken) => {


        setLoading(true);


        let locationObject = {
            name: locationId,
            password: password,
        }

        try {
            await axios.post(process.env.REACT_APP_POST_LOGIN_URL, locationObject, {headers: {Authorization: 'Bearer ' + authToken}})
                .then(res => {
                    changeLocation(res.data);
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    toast.error('An error has occurred.  Please try again.')
                });
        } catch (e) {
            setLoading(false);
            toast.error('An error has occurred.  Please try again!')
        }
    }

    return (
        <div>
            <Dialog
                style={{backgroundColor: '#00fc04'}}
                open={true}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <img src={logo} style={{width: '35%', height: 'auto', margin: 'auto'}} alt="logo"/>
                <DialogTitle id="alert-dialog-slide-title"
                             style={{
                                 color: '#038231',
                                 alignSelf: 'center'
                             }}>{"Employee Perks Redemption"} </DialogTitle>
                <DialogContent>
                    <Grid item container direction="column" alignItems="center" justify="center">
                        <Grid item style={{maxWidth: "30em", marginTop: '10px'}}>
                            <TextField label="Location ID" id="locationid" autoFocus autoComplete="off"
                                       onChange={(e) => setLocationId(e.target.value)}/>
                        </Grid>
                        <Grid item style={{maxWidth: "30em", marginTop: '10px'}}>
                            <TextField label="password" id="password" type="password" autoComplete="current-password"
                                       onChange={(e) => setPassword(e.target.value)}/>
                        </Grid>
                        {loading && <Grid item style={{ marginTop: '10px'}} alignItems="center" justify="center" ><CircularProgress/></Grid> }
                    </Grid>
                    {/*{error && <Alert severity="error">Invalid Log-in. </Alert>}*/}
                    <p/>
                    <Grid container>
                        <Grid item style={{margin: 'auto'}}>
                            <Button variant="contained" size="large"
                                    style={{minWidth: '250px', marginTop: '10px'}}
                                    onClick={getToken}>Submit</Button>
                        </Grid>
                    </Grid>
                    <p/>
                </DialogContent>
            </Dialog>
        </div>
    );
})

export default AlertDialogSlide