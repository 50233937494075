import {useContext, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "./assets/DVstaffLogo.png";
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {MainContext} from './MainContext';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import CloseIcon from '@mui/icons-material/Close';

export default function ButtonAppBar( props) {

    const classes = {
        logo: {
            margin: 'auto',
            textAlign: 'center',
            maxWidth: '100px',
            maxHeight: '200px',
        },
        logoHorizontallyCenter: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    const { window } = props;
    const drawerWidth = 240;
    const [mobileOpen, setMobileOpen] = useState(false);
    const {changeShowReport, changeShowUse} = useContext(MainContext);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleOnClick =(text)=>{
        if (text === 'Daily Report')
            changeShowReport()
        if (text === 'Employee Use')
            changeShowUse()
    }



    const drawer = (
        <div>
            <Toolbar />
            <IconButton color="warning" aria-label="close" sx={{ml:1,mt:0 }} onClick={handleDrawerToggle}>
                <CloseIcon fontSize="large" />
            </IconButton>
            <Divider />
            <List>
                {['Daily Report', 'Employee Use'].map((text, index) => (
                    <ListItem button key={text} onClick={()=>handleOnClick(text)}>
                        <ListItemIcon>
                            {index === 0 ? <FilterNoneIcon /> : <SwitchAccountIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
            {/*<Divider />*/}
            {/*<List>*/}
            {/*    {['All mail', 'Trash', 'Spam'].map((text, index) => (*/}
            {/*        <ListItem button key={text}>*/}
            {/*            <ListItemIcon>*/}
            {/*                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}*/}
            {/*            </ListItemIcon>*/}
            {/*            <ListItemText primary={text} />*/}
            {/*        </ListItem>*/}
            {/*    ))}*/}
            {/*</List>*/}
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ flexGrow: 1 }} >
            <AppBar position="static"  sx={{ bgcolor: "whitesmoke" }}>
                <Toolbar >
                    <IconButton
                        size="large"
                        edge="start"
                        color="primary"
                        aria-label="menu"
                        sx={{ ml: 2 }}
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={logo} style={classes.logo} alt="logo"/>
                    <Button color="primary" sx={{mr:2}} onClick={ props.logout}>Logout</Button>

                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            // display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    {/*<Drawer*/}
                    {/*    variant="permanent"*/}
                    {/*    sx={{*/}
                    {/*        display: { xs: 'none', sm: 'block' },*/}
                    {/*        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },*/}
                    {/*    }}*/}
                    {/*    open*/}
                    {/*>*/}
                    {/*    {drawer}*/}
                    {/*</Drawer>*/}
                </Toolbar>
            </AppBar>
        </Box>
    );
}