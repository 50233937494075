
import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid, Typography, Button, TextField, CircularProgress} from '@mui/material';
import Slide from '@mui/material/Slide';
import {MainContext} from './MainContext';
import axios from "axios";
import logo from './assets/Fred.png'
import Alert from '@mui/material/Alert';
// import failBeep from "./assets/beep-10.wav";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {toast} from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function UseDialogSlide({empID,empUse}) {

    const [error, setError] = useState(false);
    // const [audioFail] = useState(new Audio(failBeep));
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(true)
    const {changeShowUse} = useContext(MainContext)
    const [data, setData] = useState(empUse);



    useEffect( () =>{
        if(empID===''){
            toast.error('First enter an employee ID in the redemption window')
            handleClose()
        }

       },[isOpen])



    const handleClose =  () => {
        setIsOpen(false)
        changeShowUse()
    }


    const empUseTable = data.length>0?
    <TableContainer style={{maxHeight: '35vh', overflow: 'auto'}} component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow style={{backgroundColor: '#038231'}}>
                    <TableCell style={{color: 'whitesmoke'}}>Description</TableCell>
                    <TableCell align={'center'} style={{color: 'whitesmoke'}}>Date</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => (
                    <TableRow
                        key={row.redeemDate}
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell component="th" scope="row">
                            {row.description}
                        </TableCell>
                        <TableCell align={'right'}>{new Date(row.redeemDate).toLocaleDateString()}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>:<Typography variant="h5" color="red">No Data Found</Typography>


    return (
        <div>
            <Dialog
                // style={{backgroundColor: '#00fc04'}}
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <img src={logo} style={{width: '35%', height: 'auto', margin: 'auto'}} alt="logo"/>
                <DialogTitle id="alert-dialog-slide-title"
                             style={{color: '#038231', alignSelf: 'center', marginTop:'-10px',marginBottom:'-10px'}}>{"Employee Use Report"} </DialogTitle>
                <DialogContent>
                    <Grid item container direction="column" alignItems="center" justify="center">
                        <Grid item style={{maxWidth: "30em", marginTop: '10px'}}>
                            {empUseTable}
                        </Grid>
                        <Grid item style={{maxWidth: "30em", marginTop: '10px'}}>

                        </Grid>
                    </Grid>
                    <Grid item container justify="center">
                        <Grid item>
                            {loading && <CircularProgress/>}
                        </Grid>
                    </Grid>
                    {error && <Alert severity="error">Unable to build report. </Alert>}
                    <p/>
                    <Grid container>
                        <Grid item style={{margin: 'auto'}}>
                            <Button variant="contained" size="large"
                                // style={{minWidth: '250px', marginTop: '10px'}}
                                    onClick={handleClose}>Close</Button>
                        </Grid>
                    </Grid>
                    <p/>
                </DialogContent>
            </Dialog>
        </div>
    );
}